
    
<template>
  <div>
    <CModal title="Edit Monitoring Jabatan Struktural - Uji Kompetensi" color="info" :show.sync="modalEditUjiKom" size="xl">
      <CRow>
          <CCol md="6">
              <CInput label="NIP - Nama" placeholder readonly v-model="nip_nama"></CInput>
          </CCol>
      </CRow>
      <CRow>
        <CCol md="3">
          <div role="group" class="form-group">
            <label class>Tanggal</label>
            <datepicker
                placeholder="Tanggal"
                input-class="datePicker is-valid"
                v-model="form.tgl"
                :bootstrap-styling="true"
            ></datepicker>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <CInput
            label="Kompetensi Teknis"
            placeholder="Kompetensi Teknis"
            v-model="form.kompetensi_teknis"
            type="number"
            :isValid="true"
          ></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <CInput
            label="Kompetensi Manajerial"
            placeholder="Kompetensi Manajerial"
            v-model="form.kompetensi_manajerial"
            type="number"
            :isValid="true"
          ></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <CInput
            label="Kompetensi Sosial Kultural"
            placeholder="Kompetensi Sosial Kultural"
            v-model="form.kompetensi_sosial_kultural"
            type="number"
            :isValid="true"
          ></CInput>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <label>Sertifikat</label>
          <br>
          <CInputFile
            label="Sertifikat"
            placeholder="Pilih Sertifikat"
            @change="uploadFile"
            :isValid="true"
            description="Max file 2MB dan hanya menerima PDF/Image"
            custom
            v-if="!form.upload_dokumen"
          />
          <CSpinner color="success" size="sm" v-if="loadingFile" />
          <CButtonGroup size="sm" v-if="form.upload_dokumen">
          <CButton color="success" class="px-4" @click="downloadFile"
            >Download File Uji Kompetensi</CButton
          >
          <CButton color="danger" class="px-4" @click="deleteFile"
            >Hapus File</CButton
          >
          </CButtonGroup>
          <hr />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="modalEditUjiKom = false" color="danger">Tutup</CButton>
        <CSpinner color="success" size="sm" v-if="loading" />
        <CButton color="primary" class="px-4" type="submit" @click="submit()" v-else>Simpan</CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
  import axios from "axios";
  import Datepicker from "vuejs-datepicker";
  import { ValidationProvider } from "vee-validate";
  import Swal from "sweetalert2";
  export default {
    name: "ModalEditUjiKom",
    components: {
        ValidationProvider,
        Datepicker
    },
    data() {
      return {
        modalEditUjiKom: false,
        loading: false,
        items: [],
        nip_nama: null,
        form: {
          nip: null,
          tgl: null,
          kompetensi_sosial_kultural: null,
          kompetensi_teknis: null,
          kompetensi_manajerial: null,
          upload_dokumen: null,
        },
        loadingFile: false,
      };
    },
    computed: {
    },
    methods: {
      toggle(item) {
        console.log(item)
        this.items = item;
        this.modalEditUjiKom = !this.modalEditUjiKom;
        this.nip_nama = item.nip+' - '+item.d_identitas.nama;
        this.form.nip = item.nip
        this.form.tgl = item.tgl
        this.form.kompetensi_sosial_kultural = item.kompetensi_sosial_kultural
        this.form.kompetensi_teknis = item.kompetensi_teknis
        this.form.kompetensi_manajerial = item.kompetensi_manajerial
        this.form.upload_dokumen = item.upload_dokumen
      },
      async uploadFile(files) {
        const file = new FormData();
        file.append("files", files[0]);
        if (files[0]["size"] > 1024 * 1024 * 2) {
            alert("File terlalu besar harus kurang dari 2MB (> 2MB)");
            return;
        }
        if (
            files[0]["type"]  != "image/png" &&
            files[0]["type"]  != "image/jpeg" &&
            files[0]["type"]  != "application/pdf" 
        ) {
            alert("File yang Anda upload bukan PDF/Image");
            return;
        }
        try {
            this.loadingFile = true;
            let { data } = await axios.post("/api/upload", file);
            this.form.upload_dokumen = data.url;
        } catch (error) {
            console.log(error);
            this.form.upload_dokumen = null;
        } finally {
            this.loadingFile = false;
        }
      },
      downloadFile() {
        window.open(this.form.upload_dokumen, "_blank");
      },
      deleteFile() {
        this.form.upload_dokumen = null;
      },
      async submit() {
        const vm = this;
        try {
          this.loading = true;
          let { status } = await this.$store.dispatch(
            "uji_kompetensi/update",{
                data: this.form,
                id: vm.items.id,
            }
          );
          if (status >= 200 && status <= 202) {
            this.modalEditUjiKom = false;
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil tersimpan!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
                var item = {
                    nip: vm.form.nip
                }
                vm.$parent.resetTable(item)
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Data gagal tersimpan!",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            });
          }
        } catch (x) {
          console.log(x);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
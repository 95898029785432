
    
<template>
    <div>
      <CModal title="Modal Pilih Kandidat" color="info" :show.sync="modalPilih" size="xl">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">Jabatan Sebelum</legend>
          <CRow v-if="show">
            <CCol md="6">
              <CInput label="NIP - Nama" placeholder readonly v-model="nip_nama"></CInput>
              <CInput
                label="Tempat Lahir"
                placeholder
                readonly
                v-model="item.tempat_lahir"
              ></CInput>
              <CInput label="Tanggal Lahir" placeholder readonly v-model="item.tgl_lahir"></CInput>
              <CTextarea
                label="Alamat"
                placeholder
                readonly
                :value="item.alamat_jalan + ' RT :' + item.alamat_rt + ' RW :' + item.alamat_rw"
                rows="9"
              ></CTextarea>
            </CCol>
            <CCol md="6">
              <CInput label="Unit Kerja" placeholder readonly v-model="item.unit_kerja_sebelum"></CInput>
              <CInput label="Jabatan" placeholder readonly v-model="item.nama_jabatan"></CInput>
              <CInput label="Pangkat" placeholder readonly v-model="item.pangkat_golongan_ruang"></CInput>
              <CInput
                label="Status Pegawai"
                placeholder
                readonly
                v-model="item.nama_status_pegawai"
              ></CInput>
            </CCol>
          </CRow>
        </fieldset>
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">Jabatan Akhir</legend>
          <CRow v-if="show">
            <CCol md="6">
              <validation-provider rules="required" v-slot="{ errors }" name="Pejabat Penetap">
                <div role="group" class="form-group">
                  <label class>Pejabat Penetap</label>
                  <v-select
                    v-model="form.id_pejabat_penetap_jabatan"
                    :options="optionsPgw"
                    label="nama_pejabat_penetap"
                    :reduce="x => x.id_pejabat_penetap"
                    :filterable="true"
                    :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                  ></v-select>
                  <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                </div>
              </validation-provider>
              <validation-provider rules="required" v-slot="{ errors }" name="No SK Jabatan">
                <CInput
                  label="No SK Jabatan"
                  placeholder
                  v-model="form.no_sk_jabatan"
                  :invalid-feedback="errors[0]"
                  :isValid="!errors[0]"
                ></CInput>
              </validation-provider>
              <validation-provider rules="required" v-slot="{ errors }" name="Tanggal SK Jabatan">
                <div role="group" class="form-group">
                  <label class>Tanggal SK Jabatan</label>
                  <datepicker
                    placeholder="Tanggal"
                    v-model="form.tgl_sk_jabatan"
                    :bootstrap-styling="true"
                    :input-class="'datePicker'"
                  ></datepicker>
                  <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                </div>
              </validation-provider>
              <validation-provider rules="required" v-slot="{ errors }" name="Unggah SK">
                <div role="group" class="form-group">
                  <label class>Unggah SK</label>
                  <CInputFile
                    label="File SK"
                    @change="uploadFile"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    description="Max file 2MB dan hanya menerima PDF/Image"
                    custom
                    v-if="!form.upload_sk"
                  />
                  <CSpinner color="success" size="sm" v-if="loadingFile" />
                  <CButtonGroup size="sm" v-if="form.upload_sk" class="form-control">
                    <CButton color="success" class="px-4" @click="downloadFile">Download File SK</CButton>
                    <CButton color="danger" class="px-4" @click="deleteFile">Hapus File</CButton>
                  </CButtonGroup>
                </div>
              </validation-provider>
            </CCol>
            <CCol md="6">
              <CInput label="Jenis Jabatan" placeholder readonly value="STRUKTURAL"></CInput>
              <CInput label="Unit Kerja" placeholder readonly v-model="item_akhir.nama_unit_kerja"></CInput>
              <CInput label="Jabatan" placeholder readonly v-model="item_akhir.jabatan"></CInput>
              <div role="group" class="form-group">
                <label class>TMT Jabatan</label>
                <datepicker
                  placeholder="TMT Jabatan"
                  v-model="form.tmt_jabatan"
                  :bootstrap-styling="true"
                  :input-class="'datePicker'"
                ></datepicker>
              </div>
            </CCol>
          </CRow>
        </fieldset>
        <template #footer>
          <CButton @click="modalPilih = false" color="danger">Tutup</CButton>
          <CSpinner color="success" size="sm" v-if="loading" />
          <CButton color="primary" class="px-4" type="submit" @click="submit()" v-else>Simpan</CButton>
        </template>
      </CModal>
    </div>
  </template>
  <script>
  import VSelect from "vue-select";
  import Datepicker from "vuejs-datepicker";
  import axios from "axios";
  import { ValidationProvider } from "vee-validate";
  import Swal from "sweetalert2";
  import _ from "lodash";
  export default {
    name: "modalPilih",
    components: {
      ValidationProvider,
      VSelect,
      Datepicker,
    },
    data() {
      return {
        loadingFile: false,
        modalPilih: false,
        loading: false,
        items: [],
        item: [],
        item_akhir: [],
        optionsPgw: [],
        optionsJnsGuru: [],
        show: false,
        form: {
          nip: null,
          id_pejabat_penetap_jabatan: null,
          no_sk_jabatan: null,
          tgl_sk_jabatan: null,
          upload_sk: null,
          id_jenis_jabatan: 4,
          id_jabatan: null,
          id_mst_jabatan: null,
          id_eselon: 99,
          tmt_jabatan: null,
          status_sumpah_jabatan: 1,
          nama_jabatan: null,
          id_instansi: '3214',
          tmt_eselon: null,
          id_jenis_guru: null,
          id_instansi_induk: null,
          id_unit_kerja: null,
          unk: null,
          sat: null,
        },
        nip_nama: null
      };
    },
    methods: {
      toggle(item, item_akhir) {
        this.items = [];
        this.modalPilih = true;
        this.item = item;
        this.item_akhir = item_akhir;
        this.form.nip = item.nip;
        this.form.id_pejabat_penetap_jabatan = null;
        this.form.no_sk_jabatan = null;
        this.form.tgl_sk_jabatan = null;
        this.form.upload_sk = null;
        this.form.id_jenis_jabatan = 1;
        this.form.id_jabatan = item_akhir.id_jabatan;
        this.form.id_mst_jabatan = item_akhir.id_mst_jabatan;
        this.form.tmt_jabatan = null;
        this.form.nama_jabatan = item_akhir.jabatan;
        this.form.tmt_eselon = null;
        this.form.id_jenis_guru = null;
        this.form.id_instansi_induk = '6106';
        this.form.id_unit_kerja = item_akhir.id_unit_kerja;
        this.form.unk = null;
        this.form.sat = null;
        this.form.id_eselon = item_akhir.id_eselon;
        this.nip_nama = item.nip + ' - ' + item.nama_pegawai
        this.loadPejabat();
        this.show = true;
      },
      async loadPejabat() {
        let options = await this.$store.dispatch(
          "master_pejabat_penetap/autocomplete"
        );
        this.optionsPgw = options;
      },
      async uploadFile(files) {
        const file = new FormData();
        file.append("files", files[0]);
        if (files[0]["size"] > 1024 * 1024 * 2) {
          alert("File terlalu besar harus kurang dari 2MB (> 2MB)");
          return;
        }
        if (
          files[0]["type"] != "image/png" &&
          files[0]["type"] != "image/jpeg" &&
          files[0]["type"] != "application/pdf"
        ) {
          alert("File yang Anda upload bukan PDF/Image");
          return;
        }
        try {
          this.loadingFile = true;
          let { data } = await axios.post("/api/upload", file);
          this.form.upload_sk = data.url;
        } catch (error) {
          console.log(error);
          alert("Upload file gagal!");
          this.form.upload_sk = null;
        } finally {
          this.loadingFile = false;
        }
      },
      downloadFile() {
        window.open(this.form.upload_sk, "_blank");
      },
      deleteFile() {
        this.form.upload_sk = null;
      },
      async submit() {
        const vm = this;
        try {
          this.loading = true;
          let { status } = await this.$store.dispatch(
            "d_jabatan_terakhir/store_struktural",
            this.form
          );
          if (status >= 200 && status <= 202) {
            this.modalPilih = false;
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil tersimpan!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              vm.$emit("done");
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Data gagal tersimpan!",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            });
          }
        } catch (x) {
          console.log(x);
        } finally {
          this.loading = false;
        }
      },
      async onSearch(search, loading) {
        loading(true);
        await this.search(search, this);
        loading(false);
      },
      search: _.debounce(async (search, vm) => {
        if (search) {
          let options = await vm.$store.dispatch(
            "master_jenis_guru/autocomplete",
            search
          );
          vm.optionsJnsGuru = options;
        }
      }, 300),
    },
  };
  </script>
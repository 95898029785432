var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[(_vm.item)?_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit($event)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Nama Unit Kerja","placeholder":"","readonly":""},model:{value:(_vm.item.nama_unit_kerja),callback:function ($$v) {_vm.$set(_vm.item, "nama_unit_kerja", $$v)},expression:"item.nama_unit_kerja"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Jenis Jabatan","placeholder":"","readonly":""},model:{value:(_vm.nama_jenis_jabatan),callback:function ($$v) {_vm.nama_jenis_jabatan=$$v},expression:"nama_jenis_jabatan"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Jabatan","placeholder":"","readonly":""},model:{value:(_vm.item.jabatan),callback:function ($$v) {_vm.$set(_vm.item, "jabatan", $$v)},expression:"item.jabatan"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Eselon","placeholder":"","readonly":""},model:{value:(_vm.item.nama_eselon),callback:function ($$v) {_vm.$set(_vm.item, "nama_eselon", $$v)},expression:"item.nama_eselon"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Pendidikan","placeholder":"","readonly":""},model:{value:(_vm.item.nama_tingkat_pendidikan),callback:function ($$v) {_vm.$set(_vm.item, "nama_tingkat_pendidikan", $$v)},expression:"item.nama_tingkat_pendidikan"}})],1)],1),_c('hr'),_c('fieldset',{staticClass:"form-group border p-3"},[_c('legend',{staticClass:"w-auto px-2"},[_vm._v("Pegawai Existing")]),_c('CDataTable',{attrs:{"items":_vm.computedItemsBuzz,"fields":_vm.fieldsBuzz,"hover":"","small":"","border":"","loading":_vm.loadingBuzz,"pagination":true,"items-per-page":10},scopedSlots:_vm._u([{key:"pegawai",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.nip)+" - "+_vm._s(item.nama_pegawai)+" ")])]}}],null,false,2488638035)})],1),_c('fieldset',{staticClass:"form-group border p-3"},[_c('legend',{staticClass:"w-auto px-2"},[_vm._v("Kandidat")]),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Nama Pegawai")]),_c('v-select',{attrs:{"options":_vm.optionsNamaPegawai,"label":"nama_pegawai","reduce":function (pegawai) { return pegawai.nama_pegawai; },"filterable":true},on:{"search":_vm.onSearchNamaPegawai},model:{value:(_vm.filterFormKandidat.nama_pegawai),callback:function ($$v) {_vm.$set(_vm.filterFormKandidat, "nama_pegawai", $$v)},expression:"filterFormKandidat.nama_pegawai"}})],1),_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Tingkat Pendidikan")]),_c('v-select',{attrs:{"options":_vm.optionsTingkatPendidikan,"label":"nama_tingkat_pendidikan","reduce":function (tingkat_pendidikan) { return tingkat_pendidikan.id_tingkat_pendidikan; },"filterable":true},on:{"search":_vm.onSearchTingkatPendidikan},model:{value:(_vm.filterFormKandidat.id_tingkat_pendidikan),callback:function ($$v) {_vm.$set(_vm.filterFormKandidat, "id_tingkat_pendidikan", $$v)},expression:"filterFormKandidat.id_tingkat_pendidikan"}})],1)])],1),_c('CButtonGroup',{attrs:{"size":"sm"}},[_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.loadDataKandidat()}}},[_c('CIcon',{attrs:{"name":"cil-search"}}),_vm._v("Cari ")],1),_c('CButton',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.resetKandidat()}}},[_c('CIcon',{attrs:{"name":"cil-loop-circular"}}),_vm._v("Reset ")],1)],1),_c('hr'),_c('CDataTable',{attrs:{"items":_vm.computedItemsKandidat,"fields":_vm.fieldsKandidat,"hover":"","small":"","border":"","loading":_vm.loadingKandidat,"pagination":true,"items-per-page":10},scopedSlots:_vm._u([{key:"pegawai",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.nip)+" - "+_vm._s(item.nama_pegawai)+" ")])]}},{key:"jenis_jabatan",fn:function(){return [_c('td',{staticClass:"py-2"},[_vm._v(" STRUKTURAL ")])]},proxy:true},{key:"uji_kom",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{staticClass:"ml-1",attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.showModalUjiKom(item)}}},[_vm._v("Lihat")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{staticClass:"ml-1",attrs:{"color":"success","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.showModalFormPilih(item)}}},[_vm._v("Pilih")])],1)]}}],null,false,3336205458)})],1)],1):_vm._e()],1)],1),_c('modal-uji-kom',{ref:"modalUjiKom"}),_c('form-pilih',{ref:"formPilih",on:{"done":function($event){return _vm.$emit('done')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }